'use strict';

var controller = null;
var animations = function () {
    var tooSmall = false;
    var maxWidth = 1200;

    if ($(window).width() < maxWidth) {
        tooSmall = true;
    }

    function initScrollMagic() {
        controller = new ScrollMagic.Controller();
        var fixed, column, duration;
        if ($('#sidebar').length !== -1 && $('#app-content').length !== -1) {
            fixed = $('#sidebar').offset().top + $('#sidebar').outerHeight(true);
            column = $('#app-content');
            duration = column.height() - fixed;
        }

        if ($(window).width() > 1200) {
            if ($('#sidebar').length > 0) {
                new ScrollMagic.Scene({
                        triggerElement: '.app-body-sidebar',
                        offset: -25,
                        triggerHook: 0,
                        duration: duration
                    })
                    .setPin('.app-body-sidebar')
                    .addTo(controller);
            }
        }
    }

    if (!tooSmall && $('#sidebar').length) {
        if($('.app-content-column').height() > $(window).height()) {
            initScrollMagic();
        }
    }

    // zmiana szerokości okna przeglądarki może wielokrotnie odpalać skrypt
    // to rozwiązanie pozwala wstrzymać controller przed wielokrotnym wznawianiem lub zniszczeniem
    $(window).resize(function () {
        var wWidth = $(window).width();
        if (wWidth < maxWidth) {
            if (controller !== null && controller !== undefined) {
                // niszczy controller
                controller = controller.destroy(true);
            }
        } else if (wWidth >= maxWidth) {
            if (controller === null || controller === undefined) {
                // wznawia skrypt / controller jeśli nie został on wznowiony wcześniej
                if($('.app-content-column').height() > $(window).height()) {
                    initScrollMagic();
                }
            }
        }
    });
};

/** Functions **/
var textOrphans = function () {
    $('p').each(function () {

        if ($(this).html().match(/class="order"/g)) return;

        var tekst = $(this).html();
        tekst = tekst.replace(/(\s)([\S])[\s]+/g, "$1$2&nbsp;"); //jednoznakowe
        tekst = tekst.replace(/(\s)([^<][\S]{1})[\s]+/g, "$1$2&nbsp;"); //dwuznakowe
        $(this).html(tekst);
    });
};

var cookies = function () {
    if ($.cookie('giodoCookies') !== '1') {
        setTimeout(() => {
            $('.cookies-alert').fadeIn(500);
        }, 2000)
    }
    $('.cookies-alert button').on('click', function () {
        $('.cookies-alert').fadeOut('slow', function () {
            $.cookie('giodoCookies', '1', { expires: 365 });
        });
    });
};

var storage = function() {
    
    // Requrements Accepted
    if('acceptRequirements' in sessionStorage) {
        var session = sessionStorage.getItem('acceptRequirements');
        var result = $.parseJSON(session)

        if(result === true) $('#blockRequirements').hide();
    } else {
        $('#blockRequirements').fadeIn(300);
    }

    // Registration Status
    if('registrationSuccess' in sessionStorage) {
        var session = sessionStorage.getItem('registrationSuccess');
        var result = $.parseJSON(session);

        if(result === true) {
            registrationSuccessResponse('Karta została poprawnie zarejestrowana', true);        

            setTimeout(() => {
                sessionStorage.removeItem('registrationSuccess');
                $('.form-response-wrapper').remove();
            }, 6000);
        } else {
            sessionStorage.removeItem('registrationSuccess');
            $('.form-response-wrapper').remove();
        }        
    } else {
        // $('.form-response-wrapper').remove();
    }

    // Form Editable 
    if('editable' in sessionStorage) {
        var session = sessionStorage.getItem('editable');

        if(session) {
            sessionStorage.removeItem('editable');
        }
    }

    if('savedForm' in sessionStorage) {
        var session = sessionStorage.getItem('savedForm');
        var result = $.parseJSON(session)

        if(result === true) {            
            registrationSuccessResponse('Karta została poprawnie zarejestrowana', true);        

            setTimeout(() => {            
                sessionStorage.removeItem('savedForm');
                $('.form-response-wrapper').remove();
            }, 3000);
        } else {
            sessionStorage.removeItem('savedForm');
            $('.form-response-wrapper').remove();
        }
    } else {        
        // $('.form-response-wrapper').remove();
        formClear();
    }    

    // Unexpected country list package error
    if ($('#citizenship').length > 0 && $('#country').length > 0) {
        $('#citizenship option, #country option').each((i, e) => {
            if (e.value === 'XA' || e.value === 'XB') e.remove();
        });
    }
    
}

// FORM SETTINGS - VERIFICATION FORM
var verificationParsleySettings = function() {
    var validateDetails = (callback) => {
        var element = callback.$element[0];
        var success = 'rgb(158, 199, 61)';
        var errors = 'rgb(237, 51, 100)';

        return callback.validationResult === true ? element.style.borderColor = success : element.style.borderColor = errors;
    }

    $('#load-cardNumber').attr({
        'data-parsley-required': 'true',
        'data-parsley-minlength': '19',
        'data-parsley-maxlength': '19',
        'minlength': '19',
        'maxlength': '19',
        'data-parsley-error-message': 'Błędny numer karty'
    }).parsley().on('field:validated', function (callback) {
        return validateDetails(callback);
    });
    
    $('#load-birthday').attr({
        'data-parsley-type': 'date',
        'data-parsley-required': 'true',
        'data-parsley-pattern': '[0-9]{4}-(0[1-9]|1[012])-(0[1-9]|1[0-9]|2[0-9]|3[01])',
        'data-parsley-error-message': 'Błędna data urodzenia'
    }).parsley().on('field:validated', function (callback) {
        return validateDetails(callback);
    });

    $('#load-prefixPhone').attr({
        'data-parsley-required': 'true'
    }).parsley().on('field:validated', function (callback) {
        return validateDetails(callback);
    });

    $('#load-phone').attr({
        'data-parsley-required': 'true',
        'data-parsley-type': 'digits',
        'data-parsley-minlength': '9',
        'data-parsley-maxlength': '9',
        'minlength': '9',
        'maxlength': '9',
        'data-parsley-error-message': 'Błędny numer telefonu'
    }).parsley().on('field:validated', function (callback) {
        return validateDetails(callback);
    });

    verificationEvents();
}

var verificationEvents = function() {
    var cardNumber = $('#load-cardNumber');
    var prefixPhone = $('#load-prefixPhone');
    var phone = $('#load-phone');    

    $("input[type='text']").keypress(function (e) {
        var firstChar = $(this).val();
        if (e.which === 32 && firstChar === "") {
            return false;
        }
    });

    cardNumber.on('keypress keyup blur', function(e) {
        var $value = $(this).val() ;
        var n = $value.split('-').join('');            
        var ASCIICode = (e.which) ? e.which : e.keyCode;

        if(ASCIICode > 31 && (ASCIICode < 48 || ASCIICode > 57) && (e.keyCode <= 96 && e.keyCode >= 105)) {
            e.preventDefault();
        } else {
            if(n.length > 0) {
                if(n.match( new RegExp('[0-9]{1}', 'g')) ) {
                    n = n.match(new RegExp('[0-9]{1,4}', 'g')).join('-');
                } else {
                    n = n.replace(/\D/g, '');
                }
            }                
        }

        if(n.length >= 19) e.preventDefault();

        return $(this).val(n);
    });

    prefixPhone.on('change', function() {
        var that = $(this);
        var $value = that.val();
        var min_chars, max_chars;

        phone.val('');
        
        $value === '48' ? [ min_chars = 9, max_chars = 9 ] : [ min_chars = 5, max_chars = 15 ];
        
        phone.attr({ 'data-parsley-minlength': min_chars, 'data-parsley-maxlength': max_chars, 'minlength': min_chars, 'maxlength': max_chars })

        return $value;
    })

    phone.on('keypress keyup blur', function(e) {
        var $value = $(this).val(); 
        var n = $value.split(' ').join('');            
        var ASCIICode = (e.which) ? e.which : e.keyCode;              

        if(ASCIICode > 31 && (ASCIICode < 48 || ASCIICode > 57) && (e.keyCode <= 96 && e.keyCode >= 105)) {
            e.preventDefault();
        } else {
            if(n.length > 0) {
                if(n.match(new RegExp('[0-9]{1}', 'g'))) {
                    n = n.match(new RegExp('[0-9]', 'g')).join('');
                } else {
                    n = n.replace(/\D/g, '');
                }
            }                
        }

        // data.phone = $(this).val();

        return $(this).val(n);
    });
}

var verification = function() {
    var pass = 0;
    var busy = false;

    var loading = (status) => { status ? $('#requestCardDetails').addClass('form-disabled') : $('#requestCardDetails').removeClass('form-disabled') };

    var errorResponse = (response) => {
        var blockCreate = content => { return content; }

        var parent = $('.modal-card-loader-footer');
        var element = $('#card-details-error-message');

        element.remove();

        parent.prepend(
            blockCreate(`<div id="card-details-error-message" class="card-details-message">  
                <div class="message-inner">
                    <div class="message-text">
                        <p id="result-message">${response}</p>
                    </div>
                    <div class="message-icon">
                        <span>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="feather feather-alert-circle">
                                <circle cx="12" cy="12" r="10"></circle>
                                <line x1="12" y1="8" x2="12" y2="12"></line>
                                <line x1="12" y1="16" x2="12.01" y2="16"></line>
                            </svg>
                        </span>
                    </div>
                </div>                        
            </div>`)
        );
    }

    var reset = (term) => {
        var cardNumber = $('#load-cardNumber');
        var birthday = $('#load-birthday');
        var prefixPhone = $('#load-prefixPhone');
        var phone = $('#load-phone');
        var tags = [cardNumber, birthday, prefixPhone, phone];

        var resetStyles = () => {
            for(var i = 0; i < tags.length; i++) { 
                tags[i].removeAttr('style').removeClass('parsley-success');
            }
        }

        var resetForm = () => {
            for (let i = 0; i < tags.length; i++) {
                if(tags[i].hasClass('field-prefixPhone')) {
                    tags[i].val('48').removeAttr('style').removeClass('parsley-success');
                } else {
                    tags[i].val('').removeAttr('style').removeClass('parsley-success');
                }
            }
        }

        switch(term) {
            case 0:
                resetStyles();
                break;
            case 1:
                resetForm();
                break;
            default: 
                return;
        }
    }

    var dateFormatter = d => {
        var d = new Date(d),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2) month = '0' + month;
        if (day.length < 2) day = '0' + day;

        return [year, month, day].join('-');
    }

    var cardNumberConverter = n => {
        var field = $('#card');
        var setValue = n.match(/.{1,4}/g).join(',').replace(/,/g, ' ');

        field.val(setValue);
    }

    var fieldError = (field, message) => {
        var blockCreate = content => { return content; };

        field.append(
            blockCreate(`<div class="form-field-error">
                <div class="form-error-text">
                    <p>${message}</p>
                </div>
            </div>`)
        );
    }

    // Form Content Loader
    var loadForm = (edit, list, isEditable) => {
        var canEditFields = edit;
        var list = list;
        var listArray = {
            cardNumber: $('#cardNumber'),
            citizenship: $('#citizenship'),
            firstname: $('#firstname'),
            secondname: $('#secondname'),
            lastname: $('#lastname'),
            pesel: $('#pesel'),
            birthday: $('#birthday'),
            birthPlace: $('#birthPlace'),
            typeDocument: $('#typeDocument'),
            numberDocument: $('#numberDocument'),
            country: $('#country'),
            expiryDateDocument: $('#expiryDateDocument'),
            prefixPhone: $('#prefixPhone'),
            phone: $('#phone'),
            email: $('#email'),
            motherFirstname: $('#motherFirstname'),
            motherMaidenName: $('#motherMaidenName'),
            fatherFirstname: $('#fatherFirstname'),
            regulationFirst: $('#regulationFirst'),
            regulationSecond: $('#regulationSecond')
        }

        cardNumberConverter(list.cardNumber);
        listArray.cardNumber.val(list.cardNumber);
        // Insert Data into form
        listArray.citizenship.val(list.citizenship);
        listArray.firstname.val(list.firstname);
        listArray.secondname.val(list.secondname);
        listArray.lastname.val(list.lastname);
        listArray.pesel.val(list.pesel);
        listArray.birthday.val(dateFormatter(list.birthday.date));
        listArray.birthPlace.val(list.birthPlace);
        listArray.typeDocument.val(list.typeDocument);
        listArray.numberDocument.val(list.numberDocument);
        listArray.country.val(list.country);
        listArray.expiryDateDocument.val(dateFormatter(list.expiryDateDocument.date));
        listArray.prefixPhone.val(list.prefixPhone);
        listArray.phone.val(list.phone);
        listArray.email.val(list.email);
        listArray.motherFirstname.val(list.motherFirstname);
        listArray.motherMaidenName.val(list.motherMaidenName);
        listArray.fatherFirstname.val(list.fatherFirstname);

        listArray.regulationFirst.prop('checked', list.regulationFirst);
        listArray.regulationSecond.prop('checked', list.regulationSecond);
        // listArray.regulationThird.prop('checked', list.regulationThird);
        // listArray.regulationFourth.prop('checked', list.regulationFourth);

        if(list.typeDocument !== 1) {
            listArray.numberDocument.attr({
                'maxlength': 50,
                'data-parsley-pattern': null,
            })
        } else {
            listArray.numberDocument.attr({
                'maxlength': 9,
                'data-parsley-pattern': '([A-Z]{3})+([0-9]{6})',
            })
        } 

        $('#card').addClass('form-field-disabled');

        if(isEditable === true) {
            $('#validate').removeAttr('disabled');

            for(var k in listArray) {
                var fields = $('.form-field[name="client_milenium['+k+']"]');

                fields.addClass('form-field-disabled').attr('disabled', 'disabled');
            }

            for(var i = 0; i < canEditFields.length; i++) {            
                var field = $('.form-field[name="client_milenium['+canEditFields[i]+']"]');

                field.removeClass('form-field-disabled').removeAttr('disabled');

                if(canEditFields[i] === 'citizenship') 
                    fieldError($('.form-field-row[data-id="'+canEditFields[i]+'"]'), 'Podaj właściwy kraj pochodzenia');

                if(canEditFields[i] === 'firstname') 
                    fieldError($('.form-field-row[data-id="'+canEditFields[i]+'"]'), 'Podaj właściwe imię');

                if(canEditFields[i] === 'secondname') 
                    fieldError($('.form-field-row[data-id="'+canEditFields[i]+'"]'), 'Podaj właściwe drugie imię');
    
                if(canEditFields[i] === 'lastname') 
                    fieldError($('.form-field-row[data-id="'+canEditFields[i]+'"]'), 'Podaj właściwe nazwisko');

                if(canEditFields[i] === 'pesel')
                    fieldError($('.form-field-row[data-id="'+canEditFields[i]+'"]'), 'Podaj poprawny numer pesel');

                if(canEditFields[i] === 'birthday')
                    fieldError($('.form-field-row[data-id="'+canEditFields[i]+'"]'), 'Podaj poprawną datę urodzenia');

                if(canEditFields[i] === 'birthPlace')
                    fieldError($('.form-field-row[data-id="'+canEditFields[i]+'"]'), 'Podaj właściwe miejsce urodzenia');

                if(canEditFields[i] === 'typeDocument') 
                    fieldError($('.form-field-row[data-id="'+canEditFields[i]+'"]'), 'Podaj właściwy typ dokumentu');                    
    
                if(canEditFields[i] === 'numberDocument')
                    fieldError($('.form-field-row[data-id="'+canEditFields[i]+'"]'), 'Podaj poprawny numer dokumentu');
    
                if(canEditFields[i] === 'country')
                    fieldError($('.form-field-row[data-id="'+canEditFields[i]+'"]'), 'Podaj właściwy kraj wydania dokumentu');
    
                if(canEditFields[i] === 'expiryDateDocument')
                    fieldError($('.form-field-row[data-id="'+canEditFields[i]+'"]'), 'Podaj poprawną datę ważności dokumentu');
    
                if(canEditFields[i] === 'phone')
                    fieldError($('.form-field-row[data-id-phone="'+canEditFields[i]+'"]'), 'Podaj poprawny numer telefonu');

                if(canEditFields[i] === 'prefixPhone')
                    fieldError($('.form-field-row[data-id-prefix="'+canEditFields[i]+'"]'), 'Podaj poprawny numer kierunkowy');
    
                if(canEditFields[i] === 'motherFirstname')
                    fieldError($('.form-field-row[data-id="'+canEditFields[i]+'"]'), 'Podaj właściwe imię matki');
    
                if(canEditFields[i] === 'motherMaidenName')
                    fieldError($('.form-field-row[data-id="'+canEditFields[i]+'"]'), 'Podaj właściwe nazwisko panieńskie matki');
    
                if(canEditFields[i] === 'fatherFirstname')
                    fieldError($('.form-field-row[data-id="'+canEditFields[i]+'"]'), 'Podaj właściwe imię ojca');

            }
        } else {
            $('#validate').attr('disabled', 'disabled'); 

            for(var k in listArray) {
                var fields = $('.form-field[name="client_milenium['+k+']"]');
                fields.addClass('form-field-disabled').attr('disabled', 'disabled');
            }
        }
    }

    // Form Request
    $('#requestCardDetails').on('submit', function(e) {
        var form = $(this);
        busy = true;

        form.parsley().validate();
        loading(busy);

        if(pass === 0) {
            e.preventDefault();

            busy = false;
            loading(busy);

            if(form.parsley().isValid()) {
                var data = {
                    cardNumber: $('#load-cardNumber').val().replace(/-/g, ''),
                    birthday: $('#load-birthday').val(),
                    prefixPhone: $('#load-prefixPhone').val(),
                    phone: $('#load-phone').val()
                }

                axios.post('/karta-millenium/weryfikacja', {
                    cardNumber: data.cardNumber,
                    birthday: data.birthday,
                    prefixPhone: data.prefixPhone,                    
                    phone: data.phone
                })
                .then(response => {
                    $('.personal-details-error').remove();
                    $('.form-response-wrapper').remove();

                    if(response.status === 200) {
                        var editable = response.data.fieldToEdit;
                        var list = response.data.list;
                        
                        if(response.data.canEditField === true) {
                            loadForm(editable, list, true);
                            updateSuccessResponse('Edycja danych');
                        } else {
                            loadForm(editable, list, false);
                            updateSuccessResponse('Zarejestrowane dane');
                        }
                    }

                    reset(1);
                    loading(busy);

                    sessionStorage.setItem('editable', response.data.canEditField);
                    $('#loadCardInfo').fadeOut(300);

                    $('#requestCardDetails').removeClass('form-disabled');

                    if($('#card-details-error-message').length !== -1) {
                        $('#card-details-error-message').remove();
                    }
                })
                .catch(error => {    
                    busy = false;

                    if(error.response.status === 409) {
                        $('#requestCardDetails').removeClass('form-disabled');                        
                        errorResponse(error.response.data);
                    }

                    loading(busy);
                    reset(0);
                });
            } else {
                busy = false;

                loading(busy);
                reset(0);
            }
        }
    });

    if($('#loadCardInfo').length) verificationParsleySettings();
}

// FORM SETTINGS - REGISTRATION
var registrationEvents = function() {
    var cardNumberVisible = $('#card');
    var pesel = $('#pesel');
    var prefixPhone = $('#prefixPhone');
    var phone = $('#phone');
    var typeDocument = $('#typeDocument');
    var numberDocument = $('#numberDocument');

    $("input[type='text']").keypress(function (e) {
        var firstChar = $(this).val();
        if (e.which === 32 && firstChar === "") {
            return false;
        }
    });

    cardNumberVisible.on('keypress keyup blur', function(e) {
        var $value = $(this).val();
        var n = $value.split(' ').join('');
        var ASCIICode = (e.which) ? e.which : e.keyCode;

        if(ASCIICode > 31 && (ASCIICode < 48 || ASCIICode > 57) && (e.keyCode <= 96 && e.keyCode >= 105)) {
            e.preventDefault();
        } else {
            if(n.length > 0) {
                if(n.match( new RegExp('[0-9]{1}', 'g')) ) {
                    n = n.match(new RegExp('[0-9]{1,4}', 'g')).join(' ');
                } else {
                    n = n.replace(/\D/g, '');
                }
            }                
        }

        if(n.length >= 19) e.preventDefault();

        var completeNumber = n.replace(/\s+/g, '');
        $('#cardNumber').val(completeNumber);

        return $(this).val(n);
    });

    pesel.on('keypress keyup blur', function(e) {
        var $value = $(this).val();
        var n = $value.split(' ').join('');
        var ASCIICode = (e.which) ? e.which : e.keyCode;

        if(ASCIICode > 31 && (ASCIICode < 48 || ASCIICode > 57) && (e.keyCode <= 96 && e.keyCode >= 105)) {
            e.preventDefault();
        } else {
            if(n.length > 0) {
                if(n.match(new RegExp('[0-9]{1}', 'g'))) {
                    n = n.match(new RegExp('[0-9]', 'g')).join('');
                } else {
                    n = n.replace(/\D/g, '');
                }
            }                
        }
        
        if(n.length >= 11) e.preventDefault();

        return $(this).val(n);
    });

    phone.on('keypress keyup blur', function(e) {
        var $value = $(this).val();
        var max = $(this).prop('maxlength');
        var n = $value.split(' ').join('');
        var ASCIICode = (e.which) ? e.which : e.keyCode;

        if(ASCIICode > 31 && (ASCIICode < 48 || ASCIICode > 57) && (e.keyCode <= 96 && e.keyCode >= 105)) {
            e.preventDefault();
        } else {
            if(n.length > 0) {
                if(n.match(new RegExp('[0-9]{1}', 'g'))) {
                    n = n.match(new RegExp('[0-9]', 'g')).join('');
                } else {
                    n = n.replace(/\D/g, '');
                }
            }                
        }

        if(n.length >= max) e.preventDefault();

        return $(this).val(n);
    });

    // TypeDocument
    if(typeDocument.length > 0) {
        let selectedType = 1;

        var validateNumber = function(n, regex, event, max) {
            if(n.match(regex)) {
                if(n.length === max) {
                    event.preventDefault();
                }
            }

            return n;
        }

        if(selectedType === 1) {
            numberDocument.attr({
                'maxlength': 9,
                'data-parsley-pattern': '([A-Z]{3})+([0-9]{6})'
            });
            numberDocument.on('keypress', function(event) {
                let inputValue = null;
                let regex = /^([A-Z]{3})+([0-9]{6})$/i;
                inputValue = event.target.value;
                validateNumber(inputValue, regex, event);
            })
        } else {
            numberDocument.attr({
                'maxlength': 50,
                'data-parsley-pattern': null
            });
        }

        typeDocument.on('change', function() {
            selectedType = parseInt(typeDocument.val());
            if(selectedType === 1) {
                numberDocument.attr({
                    'maxlength': 9,
                    'data-parsley-pattern': '([A-Z]{3})+([0-9]{6})'
                });
                typeDocument.on('keypress', function (event) {
                    let inputValue = null;
                    let regex = /^([A-Z]{3})+([0-9]{6})$/i;
                    inputValue = event.target.value;
                    validateNumber(inputValue, regex, event);
                });
            } else {
                numberDocument.attr({
                    'maxlength': 50,
                    'data-parsley-pattern': null
                });
            }
        })
    }

    //Phone
    if(prefixPhone.length > 0) {
        let dialCode = 48;

        if(dialCode === 48) {
            phone.attr({
                minlength: 9,
                maxlength: 9,
                pattern: '[0-9]{9}',
                'data-parsley-pattern': '[0-9]{9}'
            });
        } else {
            phone.attr({
                minlength: 1,
                maxlength: 15,
                pattern: '[0-9]+',
                'data-parsley-pattern': '[0-9]+'
            });
        }

        prefixPhone.on('change', function(e) {
            let selected = parseInt(e.target.value);

            if(selected === 48) {
                phone.attr({
                    minlength: 9,
                    maxlength: 9,
                    pattern: '[0-9]{9}',
                    'data-parsley-pattern': '[0-9]{9}'
                });
            } else {
                phone.attr({
                    minlength: 1,
                    maxlength: 15,
                    pattern: '[0-9]+',
                    'data-parsley-pattern': '[0-9]+'
                });
            }
        })
    }
}

var registrationParsleySettings = function() {
    var validationComplete = function (callback) {
        var element = callback.$element[0];

        if (callback.validationResult === true) {
            element.style.borderColor = 'rgb(158, 199, 61)';
        } else {
            element.style.borderColor = 'rgb(237, 51, 100)';
        }

        return;
    }

    $('#card').attr({
        'data-parsley-required': 'true',
        'data-parsley-maxlength': '19',
        'data-parsley-minlength': '19'
    }).parsley().on('field:validated', function (callback) {
        return validationComplete(callback);
    });
    $('#cardNumber').attr({
        'data-parsley-required': 'true',
        'data-parsley-maxlength': '16',
        'data-parsley-minlength': '16'
    }).parsley().on('field:validated', function (callback) {
        return validationComplete(callback);
    });

    $('#citizenship').attr({
        'data-parsley-required': 'true'
    }).parsley().on('field:validated', function (callback) {
        return validationComplete(callback);
    });

    $('#firstname').attr({
        'data-parsley-required': 'true',
        'data-parsley-minlength': '2',
        'data-parsley-maxlength': '20',
        'data-parsley-pattern': '([À-žA-Za-z-( )]+)',
        'pattern': '([À-žA-Za-z-( )]+)'
    }).parsley().on('field:validated', function (callback) {
        return validationComplete(callback);
    });

    $('#secondname').attr({
        'data-parsley-minlength': '2',
        'data-parsley-maxlength': '20',
        'data-parsley-pattern': '([À-žA-Za-z-( )]+)',
        'pattern': '([À-žA-Za-z-( )]+)'
    }).parsley().on('field:validated', function (callback) {
        return validationComplete(callback);
    });

    $('#lastname').attr({
        'data-parsley-required': 'true',
        'data-parsley-minlength': '2',
        'data-parsley-maxlength': '30',
        'data-parsley-pattern': '([À-žA-Za-z-( )]+)',
        'pattern': '([À-žA-Za-z-( )]+)'
    }).parsley().on('field:validated', function (callback) {
        return validationComplete(callback);
    });

    $('#pesel').attr({
        'data-parsley-type': 'digits',
        'data-parsley-minlength': '11',
        'data-parsley-maxlength': '11',
        'data-parsley-required': 'true'
    }).parsley().on('field:validated', function (callback) {
        return validationComplete(callback);
    });

    $('#birthday').attr({
        'data-parsley-type': 'date',
        'data-parsley-required': 'true',
        'data-parsley-pattern': '[0-9]{4}-(0[1-9]|1[012])-(0[1-9]|1[0-9]|2[0-9]|3[01])'
    }).parsley().on('field:validated', function (callback) {
        return validationComplete(callback);
    });

    $('#birthPlace').attr({
        'data-parsley-required': 'true',
        'data-parsley-minlength': '2',
        'data-parsley-pattern': '([À-žA-Za-z-( )]+)',
        'pattern': '([À-žA-Za-z-( )]+)'
    }).parsley().on('field:validated', function (callback) {
        return validationComplete(callback);
    });

    $('#typeDocument').attr({
        'data-parsley-required': 'true'
    }).parsley().on('field:validated', function (callback) {
        return validationComplete(callback);
    });

    $('#numberDocument').attr({
        'data-parsley-required': 'true',
        'data-parsley-minlength': '5',
    }).parsley().on('field:validated', function (callback) {
        return validationComplete(callback);
    });

    $('#country').attr({
        'data-parsley-required': 'true'
    }).parsley().on('field:validated', function (callback) {
        return validationComplete(callback);
    });

    $('#expiryDateDocument').attr({
        'data-parsley-type': 'date',
        'data-parsley-required': 'true',
        'data-parsley-pattern': '[0-9]{4}-(0[1-9]|1[012])-(0[1-9]|1[0-9]|2[0-9]|3[01])'
    }).parsley().on('field:validated', function (callback) {
        return validationComplete(callback);
    });

    $('#prefixPhone').attr({
        'data-parsley-required': 'true'
    }).parsley().on('field:validated', function (callback) {
        return validationComplete(callback);
    });

    $('#phone').attr({
        'data-parsley-required': 'true',
        'data-parsley-type': 'digits',
    }).parsley().on('field:validated', function (callback) {
        return validationComplete(callback);
    });

    $('#email').attr({
        'data-parsley-minlength': '5',
        'data-parsley-type': 'email'
    }).parsley().on('field:validated', function (callback) {
        return validationComplete(callback);
    });

    $('#motherFirstname').attr({
        'data-parsley-required': 'true',
        'data-parsley-minlength': '2',
        'data-parsley-maxlength': '50',
        'data-parsley-pattern': '([À-žA-Za-z-( )]+)',
        'pattern': '([À-žA-Za-z-( )]+)'
    }).parsley().on('field:validated', function (callback) {
        return validationComplete(callback);
    });

    $('#motherMaidenName').attr({
        'data-parsley-required': 'true',
        'data-parsley-minlength': '2',
        'data-parsley-maxlength': '50',
        'data-parsley-pattern': '([À-žA-Za-z-( )]+)',
        'pattern': '([À-žA-Za-z-( )]+)'
    }).parsley().on('field:validated', function (callback) {
        return validationComplete(callback);
    });

    $('#fatherFirstname').attr({
        'data-parsley-required': 'true',
        'data-parsley-minlength': '2',
        'data-parsley-maxlength': '50',
        'data-parsley-pattern': '([À-žA-Za-z-( )]+)',
        'pattern': '([À-žA-Za-z-( )]+)'
    }).parsley().on('field:validated', function (callback) {
        return validationComplete(callback);
    });

    $('#regulationFirst').attr({ 'data-parsley-required': 'true' });
    $('#regulationSecond').attr({ 'data-parsley-required': 'true' });

    // EVENTS
    registrationEvents();
}

var registration = function() {
    var pass = 0;
    var registrationForm = $('#registrationForm');

    if(registrationForm.length !== 0) {
        registrationForm.on('submit', function(e) {
            var form = $(this);
            form.parsley().validate();

            if(pass === 0) {
                e.preventDefault();
                if(form.parsley().isValid()) {
                    if("editable" in sessionStorage) {
                        var client_milenium_history = {};

                        client_milenium_history['cardNumber'] = $('#cardNumber').val();
                        client_milenium_history['citizenship'] = $('#citizenship').val();
                        client_milenium_history['firstname'] = $('#firstname').val();
                        client_milenium_history['secondname'] = $('#secondname').val();
                        client_milenium_history['lastname'] = $('#lastname').val();
                        client_milenium_history['pesel'] = $('#pesel').val();
                        client_milenium_history['birthday'] = $('#birthday').val();
                        client_milenium_history['birthPlace'] = $('#birthPlace').val();
                        client_milenium_history['typeDocument'] = $('#typeDocument').val();
                        client_milenium_history['numberDocument'] = $('#numberDocument').val();
                        client_milenium_history['country'] = $('#country').val();
                        client_milenium_history['expiryDateDocument'] = $('#expiryDateDocument').val();
                        client_milenium_history['prefixPhone'] = $('#prefixPhone').val();
                        client_milenium_history['phone'] = $('#phone').val();
                        client_milenium_history['email'] = $('#email').val();
                        client_milenium_history['motherFirstname'] = $('#motherFirstname').val();
                        client_milenium_history['motherMaidenName'] = $('#motherMaidenName').val();
                        client_milenium_history['fatherFirstname'] = $('#fatherFirstname').val();
                        client_milenium_history['regulationFirst'] = $('#regulationFirst').prop('checked');
                        client_milenium_history['regulationSecond'] = $('#regulationSecond').prop('checked');
                        client_milenium_history['save'] = 1;

                        axios.post('/karta-millenium/edycja', { client_milenium_history })
                        .then(response => {
                            var pass = 1;
                    
                            if(response.status === 200) {
                                $('.form-response-wrapper').remove(); 
                                sessionStorage.setItem('savedForm', true);
                                form.unbind('submit').submit();
                            }
                        })
                        .catch(error => { });
                    } else {                        
                        var cardNumber = $('#cardNumber').val();

                        axios.get(`/check-card-number-milenium?item=${cardNumber}`)
                        .then(response => {
                            var pass = 1;

                            if(response.status === 200) {                                
                                $('.form-response-wrapper').remove();
                                sessionStorage.setItem('registrationSuccess', true);
                                form.unbind('submit').submit();
                            }
                        })
                        .catch(error => {
                            $('html, body').animate({ 
                                scrollTop: $('form').offset().top 
                            }, 500);

                            if(error.response) {
                                $('.form-response-wrapper').remove();
                                registrationSuccessResponse(error.response.data, false);
                            }
                        });
                    }
                }
            }
        });

        registrationParsleySettings();
    }
}

// FORM SETTINGS - FORM CLEAR
var formClear = () => {
    $('#cardNumber, #card').val('').attr('value', '');
    $('#citizenship').val('PL').attr('value', '');
    $('#firstname').val('').attr('value', '');
    $('#secondname').val('').attr('value', '');
    $('#lastname').val('').attr('value', '');
    $('#pesel').val('').attr('value', '');
    $('#birthday').val('').attr('value', '');
    $('#birthPlace').val('').attr('value', '');
    $('#typeDocument').val(1).attr('value', '');
    $('#numberDocument').val('').attr('value', '');
    $('#country').val('PL').attr('value', '');
    $('#expiryDateDocument').val('').attr('value', '');
    $('#prefixPhone').val(48).attr('value', '');
    $('#phone').val('').attr('value', '');
    $('#email').val('').attr('value', '');
    $('#motherFirstname').val('').attr('value', '');
    $('#motherMaidenName').val('').attr('value', '');
    $('#fatherFirstname').val('').attr('value', '');

    $('#regulationFirst').prop('checked', false);
    $('#regulationSecond').prop('checked', false);
}

// FORM SETTINGS - DETAILS UPDATED SUCCESSFULLY
var updateSuccessResponse = function(response) {
    var blockCreate = content => { return content };    

    var parentElement = $('.app-container-form-response');
    var childElement = $('.form-loaded');

    childElement.remove();

    parentElement.prepend(
        blockCreate(`<div class="form-loaded">
            <div class="form-loaded-text">
                <h2>${response}</h2>
            </div>
        </div>`)
    );
}

// FORM SETTINGS - REGISTRATION RESPONSE
var registrationSuccessResponse = function(response, state) {
    var blockCreate = content => { return content };    
    var parentElement = $('.app-container-form-response');
    var childElement = $('.form-response-wrapper');

    // childElement.remove();

    if(state === true) {
        parentElement.prepend(
            blockCreate(`<div class="form-response-wrapper">
                <div class="form-response-text">
                    <p id="form-response" class="form-response">${response}</p>
                </div>
            </div>`)
        );
    } else {
        parentElement.prepend(
            blockCreate(`<div class="form-response-wrapper">
                <div class="form-response-text response-error">
                    <p id="form-response" class="form-response">${response}</p>
                </div>
            </div>`)
        );
    }
}

// FAQ - Q&A List Render
var faqListDisplay = function() {
    // Setup FAQ LIST FROM ENDPOINT
    var id = $('#accordionList');
    var items = content => { return content; };

    if(id.length > 0) {
        axios.get('/faqs')
        .then(response => {            
            var list = response.data;            

            for(var i = 0; i < list.length; i++) {
                id.append(
                    items(`<div class="accordion-item">
                        <div class="accordion-item-header">
                            <a href="/" class="accordion-toggle">
                                <span class="accordion-header-text">${list[i].title}</span>                                
                                <div class="accordion-header-icon">
                                    <span class="icon-inner"></span>
                                </div>
                            </a>
                        </div>
                        <div class="accordion-item-body">
                            ${list[i].description}
                        </div>
                    </div>`)
                )
            }           
            
            questions();
        })
        .catch(error => {})
    }
}

/** Listeners **/
// Accordion Item Toggle
var questions = function() {
    $('.accordion-item').each(function(index, value) {
        if(index === 0) {            
            $(this).find($('.accordion-toggle')).addClass('accordion-toggle-active');
            $(this).find($('.accordion-item-body')).addClass('accordion-visible');
        }
    })

    $('.accordion-item a').on('click', function(e) {
        e.preventDefault();
    
        var that = $(this);
        var target = that.closest('.accordion-item-header').siblings('.accordion-item-body');
    
        $('.accordion-item-body').removeClass('accordion-visible');
        $('.accordion-toggle').removeClass('accordion-toggle-active');
    
        that.addClass('accordion-toggle-active');
        target.addClass('accordion-visible');
    
        controller = controller.destroy(true);
        animations();
    });
}

$('#readMore').on('click', function(e) {
    e.preventDefault();

    var anchorName = $(this);

    $('#descriptionCollapse').toggleClass('clause-show');

    if($('#descriptionCollapse').hasClass('clause-show')) {
        anchorName.text('Pokaż mniej');
    } else {
        anchorName.text('Pokaż więcej');
    }

    controller = controller.destroy(true);
    animations();
});

// Open requirements popup with information content
$('#blockRequirementsOpen').on('click', function(e) {
    e.preventDefault();

    if('acceptRequirements' in sessionStorage) {
        sessionStorage.removeItem('acceptRequirements');
        $('#blockRequirements').fadeIn(300);
    }
});

// Close requirements popup and save this setup inside sessionStorage
$('#blockRequirementsClose').on('click', function(e) {
    e.preventDefault();

    sessionStorage.setItem('acceptRequirements', true);    
    $('#blockRequirements').fadeOut(300);
});

// Clear form inside modal when closed on click
$('#closeCardModal').on('click', function(e) {
    e.preventDefault();

    $('#load-cardNumber').val('');
    $('#load-birthday').val('');
    $('#load-prefixPhone').val('48');
    $('#load-phone').val('');

    $('#loadCardInfo').fadeOut(300);    
});

// Show card modal with form to load details of the card 
$('#showCardInfoModal').on('click', function(e) {
    e.preventDefault();

    $('#loadCardInfo').fadeIn(300);        
});

$(window).on('load', function () {
    formClear();

    setTimeout(() => { animations(); }, 650);
});

//Prevent Firefox to keep storage and not refresh page content on reload
if(navigator.userAgent.indexOf('Mozilla') || navigator.userAgent.indexOf('Firefox') != -1) {
    window.onunload = function(){};
}

/** DOCUMENT READY **/
$(function() {

    cookies();
    textOrphans();     
    
    faqListDisplay();
    storage();
    
    verification(); // VERIFICATION FORM
    registration(); // REGISTRATION FORM
});